import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

export const ErrorBox = ({ className = '', errors }) => {
  if ( !errors || errors.length < 1 ) return null;

  return (
    <div className={cls( styles.box, className, 'border border-danger fs-4 spec__errors' )}>
      {errors.map(( error ) => (
        <div key={error}>{error}</div>
      ))}
    </div>
  );
};
